@use "../config/" as *;
.waf-team {
    @extend %m-4;
    .waf-head {
        @extend %mb-5;
        .title {
            height: auto;
            @extend %secondary-600;
            @extend %uppercase;
            @extend %font-24-pb;
        }
    }
    .waf-body {
        @extend %py-5;
        @extend %px-4;
        @extend %pure-white-900-bg;
    }
    .team-data {
        @extend %relative;
        .btn-view {
            z-index: var(--z-view-more);
            @extend %neutral-50-bg;
            @extend %pos-br;
            @include btn-animation();
            &:after {
                content: unset;
            }
            .btn-text {
                &:nth-child(2) {
                    display: none;
                }
            }
            &[aria-expanded=true] {
                bottom: var(--space-7-neg);
                .btn-text {
                    &:nth-child(1) {
                        display: none;
                    }
                    &:nth-child(2) {
                        display: flex;
                    }
                }
                & ~ .team-info {
                    max-height: unset;
                    @extend %mb-5;
                    &::after {
                        content: unset;
                    }
                }
            }
        }
    }
    .team-info {
        max-height: 28rem;
        height: auto;
        overflow: hidden;
        &:after {
            content: '';
            pointer-events: none;
            background: linear-gradient(180deg, clr(pure-white-900, 0) 0%, var(--pure-white-900) 100%);
            @extend %w-100;
            @extend %h-50;
            @extend %pos-bl;
        }
        .title {
            @extend %secondary-800;
            @extend %uppercase;
            @extend %font-16-pb;
            @extend %mb-5;
        }
        .text {
            line-height: 2rem;
            @extend %secondary-800;
            @extend %font-14-pr;
            &:not(:last-child) {
                @extend %mb-4;
            }
        }
    }
}
@include mq(col-md) {
    .waf-team {
        margin-inline: var(--space-0);
        margin-block: var(--space-8);
        .team-info {
            .text {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
        .team-data {
            .btn-view {
                &::after {
                    content: '';
                }
                &[aria-expanded=true] {
                    bottom: var(--space-9-neg);
                    & ~ .team-info {
                        margin-bottom: var(--space-6);
                    }
                }
            }
        }
    }
}
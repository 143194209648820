@use "./config/" as *;
@forward "./main-scss/";
@forward "./swiper/swiper-bundle";
@forward "./scorestrip/scorestrip";
@forward "./listing/listing-common";
@forward "./listing/news-listing";
@forward "./listing/series-listing";
@forward "./listing/almanack-listing";
@forward "./team/team";
.waf-scorestrip {
    background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-600) 133.57%)
}
.waf-almanack-listing {
    @extend %mb-10;
    .first-list {
        flex-wrap: nowrap;
        @extend %pr-0;
        @extend %mr-0;
    }
    .head-wrap {
        border-bottom: 0;
        @extend %pb-0;
    }
}
@include mq(col-xl) {
    .waf-almanack-listing {
        .first-list {
            width: 100%;
            grid-template-columns: 25% 1fr 25%;
            & > .article-item:nth-child(3) {
                grid-area: 1 / 2 / 3 / 3;
            }
            & > .article-item:nth-child(4) {
                grid-area: 1 / 3 / 2 / 4;
            }
            & > .article-item:nth-child(5) {
                grid-area: 2 / 3 / 3 / 4;
            }
        }
    }
}